@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./Poppins-Regular.ttf) format('truetype');
}

.link {
  cursor: pointer;
}

.bg-near-black {
  background-color: #3a3a3a;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
